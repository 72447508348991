import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="logo.png" />
      </header>
      <p>
        Home Page
      </p>
    </div>
  );
}

export default App;
